import React from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";
import { TAgency } from "types/TAgency";
import * as Yup from "yup";

import CommercialPropositionService from "services/CommercialPropositionsService";

import { ICommercialPropositionFormSectionProps } from "../CommercialPropositionForm";
import { ICommercialPropositionFormValues } from "../CommercialPropositionFormValidation";
import { mailValidation, phoneValidation } from "./sectionsValidation";
import { Field } from "components/atoms";
import { AgencySelect } from "components/molecules";

import { filterPJForConceptRecrutment, phoneNumberFormat } from "utils";

import {
	MAX_CONTACT_EMAIL_LENGTH,
	MAX_CONTACT_NAME_LENGTH,
	MAX_CONTACT_PHONE_LENGTH,
} from "constants_globals";

export interface ICPPreferedContactValues {
	agency?: TAgency;
	privilegedRasContactName: string;
	privilegedRasContactPhone: string;
	privilegedRasContactEmail: string;
}

export const CPPreferedContactInitialValues: ICPPreferedContactValues = {
	agency: undefined,
	privilegedRasContactName: "",
	privilegedRasContactPhone: "",
	privilegedRasContactEmail: "",
};

export const CPPreferedContactValidation = {
	agency: Yup.object().required(t("forms.field.required")),
	privilegedRasContactName: Yup.string().required(t("forms.field.required")),
	privilegedRasContactPhone: phoneValidation(true),
	privilegedRasContactEmail: mailValidation(true),
};

export const CPPreferedContact: React.FC<
	ICommercialPropositionFormSectionProps
> = ({ locked }) => {
	const { values, setFieldValue, setValues, setFieldTouched } =
		useFormikContext<ICommercialPropositionFormValues>();

	const joinPhoneNumber = (key: string, value: string) => {
		setFieldValue(key, value?.replaceAll(" ", "") ?? "");
	};

	const reformatPhoneNumber = (key: string, value: string) => {
		setFieldTouched(key, true);
		setFieldValue(key, phoneNumberFormat(value?.trim() ?? ""));
	};

	return (
		<div className="max-w-[500px] col gap-4">
			<AgencySelect
				onChange={(agency) => {
					setFieldValue("agency", agency);
					setValues({
						...values,
						agency: agency,
						agencyTempoFolder: null,
						clientCode: "",
						clientSiret: "",
						isCustomClientCode: false,
						clientCompany: "",
						commercialPropositionPjs: filterPJForConceptRecrutment(
							values.commercialPropositionPjs,
							agency
						),
					});
				}}
				onFetched={(agencies) => {
					// values.agency is a number when the form datas are loaded from the backend
					if (typeof values.agency === "number") {
						let id = values.agency as number;
						let agency = agencies.find(
							(agency) => agency.id === id
						);
						if (agency) {
							values.agency = agency; // don't use setFieldValue here to avoid setting the field as dirty
						}
					}
				}}
				getOptionLabel={(option: any) =>
					`${option.name} (${option.tempoDirectory})`
				}
				name="agency"
				required
				value={values.agency}
				disabled={locked}
				query={{
					fn: () => CommercialPropositionService.getAgencies(),
					key: ["commercialProposition", "agencies"],
				}}
			/>
			<Field
				name="privilegedRasContactName"
				label={t("commercialPropositions.form.sections.contact.name")}
				maxLength={MAX_CONTACT_NAME_LENGTH}
				required
				disabled={locked}
			/>
			<Field
				name="privilegedRasContactPhone"
				label={t("commercialPropositions.form.sections.contact.phone")}
				maxLength={MAX_CONTACT_PHONE_LENGTH}
				required
				onFocus={() =>
					joinPhoneNumber(
						"privilegedRasContactPhone",
						values.privilegedRasContactPhone
					)
				}
				onBlur={() =>
					reformatPhoneNumber(
						"privilegedRasContactPhone",
						values.privilegedRasContactPhone
					)
				}
				valueLength={
					values.privilegedRasContactPhone?.replaceAll(" ", "").length
				}
				disabled={locked}
			/>
			<Field
				name="privilegedRasContactEmail"
				label={t("commercialPropositions.form.sections.contact.email")}
				maxLength={MAX_CONTACT_EMAIL_LENGTH}
				required
				disabled={locked}
			/>
		</div>
	);
};
