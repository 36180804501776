import moment from "moment";

import { getTime } from "date-fns";

import { isNumber } from "./numbers";
import { ITimeSheet, ITimeSheetDay } from "components/types";

import {
	convertHundredsToHours,
	formatDate,
	formatHundredsToString,
} from "utils";

import { HOURS_DAYS_TYPES } from "constants_globals/dates";

// get the total of workedHours or nightHours
export const getTotalRow = (name: string, values: ITimeSheetDay[]) => {
	if (!values?.length) return 0;

	return values.reduce(
		(acc: number, value: ITimeSheetDay) =>
			isNumber(value[name]) ? acc + value[name] : acc,
		0
	);
};

// format the value from string to hundreds
export const formatStringToHundreds = (value: any) => {
	if (value || value === 0) {
		const explode = String(value).split(",");
		return parseFloat(`${explode[0]}.${explode[1]}`);
	}
	return null;
};

// convert the value in timemode "hours" to "hundreds"
export const convertHoursToHundreds = (value: any, colon: any) => {
	if (value || value === 0) {
		const arr = value.split(colon);
		const dec = Math.round((arr[1] / 6) * 100 + Number.EPSILON + 0.25);

		return parseFloat(
			`${parseInt(arr[0], 10)}.${dec < 100 ? "0" : ""}${dec}`
		);
	}
	return null;
};

// get the total of workedHours or nightHours when timemode is "hundreds"
export const initDaysHundreds = (
	days: ITimeSheetDay[],
	isHundredMode: boolean
) => {
	if (!days?.length) return [];

	const daysConverted = days.reduce((acc: any, day: any) => {
		acc.push({
			...day,
			workedHours: isHundredMode
				? formatStringToHundreds(day.workedHours)
				: convertHoursToHundreds(day.workedHours, ":"),
			nightHours: isHundredMode
				? formatStringToHundreds(day.nightHours)
				: convertHoursToHundreds(day.nightHours, ":"),
		});
		return acc;
	}, []);
	return daysConverted;
};

// check if the date to check is in timesheet range
const dateInTimesheet = (date: string | Date, timesheet: ITimeSheet) => {
	const startTimesheet = moment(timesheet.startDate).startOf("day");
	const endTimesheet = moment(timesheet.endDate).endOf("day");
	const momentItem = moment(new Date(date));

	const dateIsInTimesheet =
		(momentItem.isAfter(startTimesheet) &&
			momentItem.isBefore(endTimesheet)) ||
		momentItem.isSame(startTimesheet, "day") ||
		momentItem.isSame(endTimesheet, "day");

	return dateIsInTimesheet;
};

// Check if the timesheet has days with workedHours or nightHours equal to 0
const getRowZeroValue = (name: string, values: any) => {
	if (!values?.length) return 0;

	return values.reduce(
		(acc: number, value: ITimeSheetDay) =>
			isNumber(value[name]) && value[name] === 0 ? acc + 1 : acc,
		0
	);
};

// get the total of workedHours and nightHours and format the value with the matching timemode
export const checkExistingValuesDays = (
	values: ITimeSheetDay[],
	isHundredMode: boolean
) => {
	let vacationDayWorked = 0;
	const tsDaysConverted = values.reduce((acc: any[], day) => {
		acc.push({
			...day,
			workedHours: isHundredMode
				? formatStringToHundreds(day.workedHours)
				: convertHoursToHundreds(day.workedHours, ":"),
			nightHours: isHundredMode
				? formatStringToHundreds(day.nightHours)
				: convertHoursToHundreds(day.nightHours, ":"),
		});

		if (day.isVacationDay && day.isNonWorkingDay !== null)
			vacationDayWorked = 1;

		return acc;
	}, []);

	const result =
		HOURS_DAYS_TYPES.map((item) =>
			getTotalRow(item.name, tsDaysConverted)
		).reduce((acc, item) => acc + item, 0) +
			vacationDayWorked >
		0;

	const containZeroValues =
		HOURS_DAYS_TYPES.map((item) =>
			getRowZeroValue(item.name, tsDaysConverted)
		).reduce((acc, item) => acc + item, 0) > 0;

	return result || containZeroValues;
};

// convert the values of workedHours and nightHours between "hundreds" and "hours"
export const initDaysConverted = (
	days: ITimeSheetDay[],
	isHundredMode: boolean
) => {
	if (!days?.length) return [];

	const daysConverted = days.reduce((acc: any, day: any) => {
		acc.push({
			...day,
			workedHours: isHundredMode
				? formatHundredsToString(day.workedHours)
				: convertHundredsToHours(day.workedHours),
			nightHours: isHundredMode
				? formatHundredsToString(day.nightHours)
				: convertHundredsToHours(day.nightHours),
		});
		return acc;
	}, []);
	return daysConverted;
};

// init the days of the timesheet with the values of the week
export const initTimesheetDays = (
	days: ITimeSheetDay[],
	week: ITimeSheetDay[],
	timesheet: ITimeSheet
) =>
	week.reduce((acc: ITimeSheetDay[], { date }) => {
		const dateIsInTimesheet = dateInTimesheet(date, timesheet);

		const findEqualDate =
			days?.find((day: ITimeSheetDay) => {
				return formatDate(date) === formatDate(day.date);
			}) || false;

		if (findEqualDate)
			acc.push({
				...findEqualDate,
				dateIsInTimesheet,
			});
		else
			acc.push({
				id: getTime(date) * -1,
				date,
				isWorkingDay: false,
				isVacationDay: false,
				isNonWorkingDay: false,
				dateIsInTimesheet,
			});

		return acc;
	}, []);

export const formatDataTimesheet = (timesheet: ITimeSheet) => {
	const days = timesheet.days.reduce((acc: any, item) => {
		const checkDaysValues = HOURS_DAYS_TYPES.reduce(
			(accDays: any, { name }) => {
				// eslint-disable-next-line no-param-reassign
				accDays[name] = item[name] === "" ? "" : item[name];
				return accDays;
			},
			{}
		);
		acc.push({ ...item, ...checkDaysValues });
		return acc;
	}, []);
	const bonuses = timesheet.bonuses.map((bonus) => {
		const newDays = bonus.days.reduce((acc: any, day) => {
			const newValue = day.value === "" ? "" : day.value;
			acc.push({ ...day, value: newValue });
			return acc;
		}, []);
		return { ...bonus, days: newDays };
	});
	return { ...timesheet, days, bonuses };
};

export const checkEachDaysAsValues = (values: any, isHundredMode: boolean) => {
	return values
		.reduce((acc: any, day: any) => {
			acc.push({
				...day,
				workedHours: isHundredMode
					? formatStringToHundreds(day.workedHours)
					: convertHoursToHundreds(day.workedHours, ":"),
				nightHours: isHundredMode
					? formatStringToHundreds(day.nightHours)
					: convertHoursToHundreds(day.nightHours, ":"),
			});
			return acc;
		}, [])
		.map((item: any) => {
			if (
				(item.isWorkingDay &&
					item.workedHours < 0 &&
					!item.activatedDay) ||
				(item.isWorkingDay && !item.workedHours && !item.activatedDay)
			) {
				return false;
			}
			return true;
		})
		.includes(false);
};
