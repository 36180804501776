import React, { useCallback, useRef } from "react";

import { useFormikContext } from "formik";
import { t } from "i18next";
import { Trans } from "react-i18next";
import * as Yup from "yup";

import CommercialPropositionService from "services/CommercialPropositionsService";

import { ICommercialPropositionFormSectionProps } from "../CommercialPropositionForm";
import { ICommercialPropositionFormValues } from "../CommercialPropositionFormValidation";
import {
	mailValidation,
	nameValidation,
	phoneValidation,
} from "./sectionsValidation";
import {
	Title,
	InfoMessage,
	SelectAsync,
	Tooltip,
} from "@zolteam/react-ras-library";
import { Field, Toggle } from "components/atoms";
import { ClientInfosFileModal } from "components/molecules";

import { displayErrorMessage, phoneNumberFormat } from "utils";

import {
	MAX_ADDRESS_LENGTH,
	MAX_CITY_LENGTH,
	MAX_COMPANY_LENGTH,
	MAX_CONTACT_EMAIL_LENGTH,
	MAX_CONTACT_NAME_LENGTH,
	MAX_CONTACT_PHONE_LENGTH,
	MAX_GORUP_NAME_LENGTH,
	MAX_SOCIAL_REASON_LENGTH,
	MAX_ZIP_CODE_LENGTH,
} from "constants_globals";

interface ISignatoryFields {
	isInterlocutorSignatory: boolean;
	signatoryClientContactName: string;
	signatoryClientContactEmail: string;
}

export interface ICPCompanyValues extends ISignatoryFields {
	agencyTempoFolder: any;
	clientCode: string;
	isCustomClientCode: boolean;
	clientCompany: string;
	clientSiret: string;
	privilegedClientContactName: string;
	privilegedClientContactPhone: string;
	privilegedClientContactEmail: string;

	// used only when fetched from the API, else we use the ISignatoryFields during edition
	signatoryInterlocutor?: ISignatoryFields;

	clientSocialReason: string;
	clientAddress: string;
	clientPostalCode: string;
	clientCity: string;
	clientGroup: string;
}

export const CPCompanyInitialValues: ICPCompanyValues = {
	agencyTempoFolder: null,
	clientCode: "",
	isCustomClientCode: false,
	clientCompany: "",
	clientSiret: "",
	privilegedClientContactName: "",
	privilegedClientContactPhone: "",
	privilegedClientContactEmail: "",

	isInterlocutorSignatory: false,
	signatoryClientContactName: "",
	signatoryClientContactEmail: "",

	clientSocialReason: "",
	clientAddress: "",
	clientPostalCode: "",
	clientCity: "",
	clientGroup: "",
};

export const CPCompanyValidation = {
	agencyTempoFolder: Yup.string().required(t("forms.field.required")),
	clientCode: Yup.string().required(t("forms.field.required")),
	clientCompany: Yup.string().required(t("forms.field.required")),
	privilegedClientContactName: nameValidation(),
	privilegedClientContactPhone: phoneValidation(),
	privilegedClientContactEmail: mailValidation(true),

	isInterlocutorSignatory: Yup.boolean(),
	signatoryClientContactName: Yup.string().when("isInterlocutorSignatory", {
		is: (value: any) => value === true,
		then: () => Yup.string().required(t("forms.field.required")),
		otherwise: () => Yup.string().nullable(),
	}),
	signatoryClientContactEmail: Yup.string().when("isInterlocutorSignatory", {
		is: (value: any) => value === true,
		then: () => mailValidation(true),
		otherwise: () => Yup.string().nullable(),
	}),
};

export const CPCompany: React.FC<ICommercialPropositionFormSectionProps> = ({
	locked,
}) => {
	const { values, setValues, setFieldValue, setFieldTouched } =
		useFormikContext<ICommercialPropositionFormValues>();
	// used to delay the search while typing
	const promiseOptionsTM = useRef<any>(undefined);
	const promiseOptionDelayInMs = 500;

	const [ClientFileModalOpenFor, setIsClientFileModalOpen] =
		React.useState<any>();

	// used to create a custom tempo client code if there is no result from the API
	const genCustomTempoClientCodeValue = (value: any) => [
		{
			tempoId: value.toUpperCase(),
			agencyTempoDirectory: values.agency?.tempoDirectory,
			isCustomClientCode: true,
			id: 0,
			siret: "",
			socialReason: "",
			city: "",
			postalCode: "",
			address: "",
			group: "",
		},
	];

	const handleChangeCodeClient = (option: any) => {
		setValues({
			...values,
			agencyTempoFolder: option.agencyTempoDirectory,
			clientCode: option.tempoId,
			isCustomClientCode: option.isCustomClientCode || false,
			clientCompany: option.socialReason ?? "",
			clientSiret: option.siret ?? "",
			clientSocialReason: option.socialReason ?? "",
			clientCity: option.city ?? "",
			clientPostalCode: option.postalCode ?? "",
			clientAddress: option.address ?? "",
			clientGroup: option.group ?? "",
		});
	};

	const handleToggleSignatory = (value: boolean) => {
		setValues({
			...values,
			isInterlocutorSignatory: value,
			body: t("commercialPropositions.form.body", {
				recipient: values.privilegedClientContactName,
				action: value
					? t("commercialPropositions.form.validate")
					: t("commercialPropositions.form.sign"),
			}),
		});
	};

	const joinPhoneNumber = (key: string, value: string) => {
		setFieldValue(key, value?.replaceAll(" ", "") ?? "");
	};

	const reformatPhoneNumber = (key: string, value: string) => {
		setFieldTouched(key, true);
		setFieldValue(key, phoneNumberFormat(value?.trim() ?? ""));
	};

	const handleClickCompanyInterlocutor = useCallback(
		(option: any) => {
			Object.entries(ClientFileModalOpenFor ?? {}).forEach(
				(value: any) => {
					if (value[0] === "forSignatory") return;

					setFieldValue(
						value[0],
						option[value[1]] ? option[value[1]].trim() : "",
						true
					).then(() => {
						if (option[value[1]]) setFieldTouched(value[0], true);
					});
				}
			);

			if (!ClientFileModalOpenFor?.forSignatory)
				setFieldValue(
					"body",
					t("commercialPropositions.form.body", {
						recipient: option.fullName,
						action: values.isInterlocutorSignatory
							? t("commercialPropositions.form.validate")
							: t("commercialPropositions.form.sign"),
					})
				);

			setIsClientFileModalOpen(false);
		},
		[ClientFileModalOpenFor, setFieldTouched, setFieldValue, values]
	);

	const handleFetch = (inputValue: string) => {
		// we clear the previous timeout to avoid too many requests
		clearTimeout(promiseOptionsTM.current);
		const prom = new Promise((resolve, reject) => {
			// we set a timeout to avoid too many requests
			promiseOptionsTM.current = setTimeout(() => {
				return CommercialPropositionService.getClients(
					values.agency?.tempoDirectory,
					inputValue
				)
					.then((res) => {
						const options = res?.length
							? res
							: genCustomTempoClientCodeValue(inputValue);

						return resolve(options);
					})
					.catch((err) => {
						displayErrorMessage(err);
						reject([]);
					});
			}, promiseOptionDelayInMs);
		});
		return prom;
	};

	return (
		<div className="gap-4 col">
			<div className="gap-4 col">
				<Title tag="h3" size="heading03" className="dark:text-white">
					{t("commercialPropositions.form.sections.company.infos")}
				</Title>
				<InfoMessage color="primary" withIcon>
					<b>
						{t(
							"commercialPropositions.form.sections.company.tempoDelayInfoMessage"
						)}
					</b>
					<ul className="ml-4 list-disc">
						<li>
							{t(
								"commercialPropositions.form.sections.company.infoMessage1"
							)}
						</li>
						<li>
							{t(
								"commercialPropositions.form.sections.company.infoMessage2"
							)}
						</li>
					</ul>
				</InfoMessage>
				<div>
					<SelectAsync
						label={t(
							!values.agency
								? "commercialPropositions.form.sections.company.tempoCodeNoAgencySelected"
								: "commercialPropositions.form.sections.company.tempoCode"
						)}
						promiseOptions={handleFetch}
						cacheOptions={false}
						name="agencyTempoFolder"
						disabled={locked || !values.agency}
						getOptionLabel={(option: any) =>
							`${option.agencyTempoDirectory} - ${option.tempoId}`
						}
						getOptionValue={(option: any) => option.tempoId}
						onChange={handleChangeCodeClient}
						noOptionsMessage={() =>
							t(
								"commercialPropositions.form.sections.company.selectNoClientCodesFound"
							)
						}
						value={
							values.agencyTempoFolder && values.clientCode
								? {
										tempoId: values.clientCode,
										agencyTempoDirectory:
											values.agencyTempoFolder,
								  }
								: null
						}
						loadingMessage={() => t("global.loading")}
					/>
					<div className="items-center gap-2 row">
						<span className="ml-6 text-xs italic text-neutral-400">
							{t("forms.field.mandatory")}
						</span>
						<Tooltip
							tooltipBoxStyle={{
								maxWidth: "500px",
							}}
							content={
								<div className="text-left w-[200px]">
									{t(
										"commercialPropositions.form.sections.company.signatoryInfo"
									)}
									<ul className="pl-4 mt-2 list-disc">
										<li>
											{t(
												"commercialPropositions.form.sections.company.signatoryInfo1"
											)}
										</li>
										<li>
											{t(
												"commercialPropositions.form.sections.company.signatoryInfo2"
											)}
										</li>
									</ul>
								</div>
							}
						>
							<div className="items-center justify-center w-4 h-4 text-xs text-white rounded-full col bg-primary-500">
								?
							</div>
						</Tooltip>
					</div>
					{values.isCustomClientCode && (
						<div>
							<InfoMessage color="warning" withIcon>
								<Trans
									i18nKey={
										"commercialPropositions.form.sections.company.customClientCodeSelected"
									}
								/>
							</InfoMessage>
						</div>
					)}
				</div>
				<Field
					label={t(
						"commercialPropositions.form.sections.company.company"
					)}
					name="clientCompany"
					type="text"
					maxLength={MAX_COMPANY_LENGTH}
					required
					disabled={locked}
				/>
				<Field
					label={t(
						"commercialPropositions.form.sections.company.siret"
					)}
					name="clientSiret"
					type="text"
					disabled={locked}
				/>
				<div>
					<Field
						label={t(
							"commercialPropositions.form.sections.contact.name"
						)}
						name="privilegedClientContactName"
						type="text"
						maxLength={MAX_CONTACT_NAME_LENGTH}
						disabled={locked}
						onChangeCapture={(e: any) => {
							setFieldValue(
								"body",
								t("commercialPropositions.form.body", {
									recipient: e.target.value,
									action: values.isInterlocutorSignatory
										? t(
												"commercialPropositions.form.validate"
										  )
										: t("commercialPropositions.form.sign"),
								})
							);
						}}
					/>
					<div className="items-center justify-between gap-2 px-6 row">
						<span className="text-xs italic text-neutral-400">
							{t("forms.field.mandatory")}
						</span>
						<button
							type="button"
							className="text-xs font-medium text-primary-500 dark:text-primary-300"
							onClick={() => {
								setIsClientFileModalOpen({
									privilegedClientContactName: "fullName",
									privilegedClientContactEmail: "email",
									privilegedClientContactPhone: "phone",
								});
							}}
							disabled={locked}
						>
							{t(
								"commercialPropositions.form.sections.company.interlocutors"
							)}
						</button>
					</div>
				</div>
				<Field
					label={t(
						"commercialPropositions.form.sections.contact.phone"
					)}
					name="privilegedClientContactPhone"
					type="tel"
					maxLength={MAX_CONTACT_PHONE_LENGTH}
					onFocus={() =>
						joinPhoneNumber(
							"privilegedClientContactPhone",
							values.privilegedClientContactPhone
						)
					}
					onBlur={() =>
						reformatPhoneNumber(
							"privilegedClientContactPhone",
							values.privilegedClientContactPhone
						)
					}
					valueLength={
						values.privilegedClientContactPhone?.replaceAll(" ", "")
							.length
					}
					disabled={locked}
				/>
				<Field
					label={t(
						"commercialPropositions.form.sections.contact.email"
					)}
					name="privilegedClientContactEmail"
					type="email"
					maxLength={MAX_CONTACT_EMAIL_LENGTH}
					required
					disabled={locked}
				/>
				<Toggle
					name="isInterlocutorSignatory"
					label={t(
						"commercialPropositions.form.sections.company.signatory"
					)}
					checked={values.isInterlocutorSignatory}
					onChange={(e) => handleToggleSignatory(e.target.checked)}
					disabled={locked}
				/>

				{values.isInterlocutorSignatory && (
					<>
						<InfoMessage color="primary" withIcon>
							<Trans
								i18nKey={
									"commercialPropositions.form.sections.company.interlocutorsInfo"
								}
							/>
						</InfoMessage>
						<div>
							<Field
								label={t(
									"commercialPropositions.form.sections.contact.name"
								)}
								name="signatoryClientContactName"
								type="text"
								maxLength={MAX_CONTACT_NAME_LENGTH}
								disabled={locked}
							/>
							<div className="items-center justify-between gap-2 px-6 row">
								<span className="text-xs italic text-neutral-400">
									{t("forms.field.mandatory")}
								</span>
								<button
									type="button"
									className="text-xs font-medium text-primary-500 dark:text-primary-300"
									onClick={() => {
										setIsClientFileModalOpen({
											forSignatory: true,
											signatoryClientContactName:
												"fullName",
											signatoryClientContactEmail:
												"email",
										});
									}}
									disabled={locked}
								>
									{t(
										"commercialPropositions.form.sections.company.interlocutors"
									)}
								</button>
							</div>
						</div>
						<Field
							label={t(
								"commercialPropositions.form.sections.contact.email"
							)}
							name="signatoryClientContactEmail"
							type="email"
							maxLength={MAX_CONTACT_EMAIL_LENGTH}
							required
							disabled={locked}
						/>
					</>
				)}

				<Title tag="h3" size="heading03" className="dark:text-white">
					{t(
						"commercialPropositions.form.sections.company.personalInfos"
					)}
				</Title>

				<Field
					label={t(
						"commercialPropositions.form.sections.company.socialReason"
					)}
					name="clientSocialReason"
					type="text"
					maxLength={MAX_SOCIAL_REASON_LENGTH}
					disabled={locked}
				/>
				<Field
					label={t(
						"commercialPropositions.form.sections.company.address"
					)}
					name="clientAddress"
					type="text"
					maxLength={MAX_ADDRESS_LENGTH}
					disabled={locked}
				/>
				<div className="flex-wrap md:flex-nowrap gap-4 row [&>div]:w-full md:[&>div]:w-1/2">
					<Field
						label={t(
							"commercialPropositions.form.sections.company.postalCode"
						)}
						name="clientPostalCode"
						type="text"
						maxLength={MAX_ZIP_CODE_LENGTH}
						disabled={locked}
					/>
					<Field
						label={t(
							"commercialPropositions.form.sections.company.city"
						)}
						name="clientCity"
						type="text"
						maxLength={MAX_CITY_LENGTH}
						disabled={locked}
					/>
				</div>
				<Field
					label={t(
						"commercialPropositions.form.sections.company.group"
					)}
					name="clientGroup"
					type="text"
					maxLength={MAX_GORUP_NAME_LENGTH}
					disabled={locked}
				/>
			</div>
			<ClientInfosFileModal
				isOpen={!!ClientFileModalOpenFor}
				onClose={() => {
					setIsClientFileModalOpen(false);
				}}
				onSelect={handleClickCompanyInterlocutor}
			/>
		</div>
	);
};
