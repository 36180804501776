import api from "./api";

// Adjust the import path as needed

class ThreadServiceClass {
	postThreadFromOther = (data: any) =>
		api
			.post(`/thread/create`, {
				...data,
				invoiceNumber: parseFloat(data.invoiceNumber),
			})
			.then((resp) => resp?.data);

	postThread = (id: number, data: any) =>
		api.post(`/thread/${id}/submit`, data).then((resp) => resp?.data);

	postThreadStatus = (id: number, data: any) =>
		api.post(`/thread/${id}/status`, data).then((resp) => resp?.data);

	postThreadSearch = (data: any, signal?: AbortSignal) =>
		api
			.post(`/thread/search`, data, {
				signal,
			})
			.then((resp) => resp?.data);

	getThread = (id: number) =>
		api.get(`/thread/${id}`).then((resp) => resp?.data);

	getThreadActives = (data: any, signal: AbortSignal) =>
		api
			.post(`/thread/get-all-actives`, data, {
				signal,
			})
			.then((resp) => resp?.data);

	getThreadArchives = (signal: AbortSignal) =>
		api
			.get(`/thread/get-all-archived`, {
				signal,
			})
			.then((resp) => resp?.data);

	getThreadsCountUnread = () =>
		api.get(`/thread/count-all-unread`).then((resp) => resp?.data);
}

const ThreadService = new ThreadServiceClass();
export default ThreadService;
