import { t } from "i18next";
import * as Yup from "yup";

import { CommercialPropositionFormSections } from "./CommercialPropositionFormSections";
import { ICPBillingSectionValues } from "./Sections/CPBillingSection";
import { ICPCommunicationSectionValues } from "./Sections/CPCommunicationSection";
import { ICPConditionSectionValues } from "./Sections/CPConditionsSection";
import { ICPDateSectionValues } from "./Sections/CPDateSection";
import { ICPOurCommercialPropositionValues } from "./Sections/CPOurCommercialProposition";
import { ICPPreferedContactValues } from "./Sections/CPPreferedContactSection";
import { ICPPrestationSectionValues } from "./Sections/CPPrestationsSection";
import { ICPCompanyValues } from "./Sections/CPcompanySection";
import {
	ISubmitCPFormModalProps,
	SubmitCPFormModalValidation,
} from "components/organisms";

interface ICPFormMessage {
	body: string;
	subject: string;
}

interface IPJ {
	pjFile: any; // base64
	pjName: string;
}

interface ICPFormDefaultValues extends ICPFormMessage {
	id?: number;
	type: string;
	message?: ICPFormMessage;
	status?: string;
	commercialPropositionPjs?: IPJ[];
	defaultPjs?: IPJ[];
}

interface ICPCustomValues {
	/*
	 **	cpType: untranslated value: "partner", "seasonal", "different"
	 **		-> used for data validation in `CPOurCommercialProposition.tsx`
	 */
	cpType: string;
}

export interface ICommercialPropositionFormValues
	extends ICPFormDefaultValues,
		ICPCustomValues,
		ICPDateSectionValues,
		ICPPreferedContactValues,
		ICPCompanyValues,
		ICPOurCommercialPropositionValues,
		ICPPrestationSectionValues,
		ICPConditionSectionValues,
		ICPCommunicationSectionValues,
		ICPBillingSectionValues,
		ISubmitCPFormModalProps {}

export const CommercialPropositionFormInitialValues: ICommercialPropositionFormValues =
	{
		...(CommercialPropositionFormSections.reduce((acc, section) => {
			return {
				...acc,
				...section.initialValues,
			};
		}, {}) as ICommercialPropositionFormValues),
		type: t("commercialPropositions.types.partner"),
		subject: t("commercialPropositions.form.subject"),
		body: t("commercialPropositions.form.body"),
		commercialPropositionPjs: [],
	};

export const CommercialPropositionFormValidation = () => {
	return Yup.object().shape(
		CommercialPropositionFormSections.reduce(
			(acc, section) => {
				return {
					...acc,
					...section.validationSchema,
				};
			},
			{
				...SubmitCPFormModalValidation,
			}
		)
	);
};
