import { ResponseType } from "axios";

export interface IConfigExport {
	responseType: ResponseType;
	headers: {
		"Content-Type"?: string;
		Accept?: string;
	};
}

export const CONFIG_EXPORT_CSV: IConfigExport = {
	responseType: "blob",
	headers: {
		Accept: "xlsx",
	},
};

export const CONFIG_EXPORT_XLSX: IConfigExport = {
	headers: {
		Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
	},
	responseType: "arraybuffer",
};

export const CONFIG_EXPORT_PDF: IConfigExport = {
	responseType: "blob",
	headers: {
		Accept: "pdf",
	},
};

export const CONFIG_EXPORT_ZIP: IConfigExport = {
	responseType: "blob",
	headers: {
		Accept: "zip",
		"Content-Type": "multipart/form-data",
	},
};
