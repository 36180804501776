import { t } from "i18next";
import { TBanner, TBannerEdit, TBannerVisibility } from "types/TBanners";

interface IBannersViewItemActionsProps {
	onEdit: (item: TBannerEdit) => void;
	onDisable: (item: TBanner) => void;
}

export const bannersItemsActions = ({
	onEdit,
	onDisable,
}: IBannersViewItemActionsProps) => {
	const getVisibilityFromExisting = (item: TBanner): TBannerVisibility[] => {
		const defaultReturn: TBannerVisibility[] = [
			"agency_only",
			"client_only",
		];
		return !item.visibility || item.visibility === "everyone"
			? defaultReturn
			: [...defaultReturn, "everyone"].includes(item.visibility)
			? [item.visibility]
			: defaultReturn;
	};

	const handleEdit = (item: TBanner) => {
		onEdit({
			...item,
			visibilities: getVisibilityFromExisting(item),
			type: { label: item.type, value: item.type },
		});
	};

	const handleEnable = (item: TBanner) => {
		onEdit({
			...item,
			id: null,
			type: { label: item.type, value: item.type },
			createdAt: null,
			updatedAt: null,
			deletedAt: null,
			isFromExisting: true,
			visibilities: getVisibilityFromExisting(item),
		});
	};

	return [
		{
			label: t("banners.edit"),
			icon: "edit",
			onClick: handleEdit,
			render: (item: TBanner, defaultRender: React.ReactNode) =>
				!item.deletedAt && defaultRender,
		},
		{
			label: t("banners.disable"),
			icon: "eyeOff",
			onClick: onDisable,
			render: (item: TBanner, defaultRender: React.ReactNode) =>
				!item.deletedAt && defaultRender,
		},
		{
			label: t("banners.enable"),
			icon: "edit",
			onClick: handleEnable,
			render: (item: TBanner, defaultRender: React.ReactNode) =>
				!!item.deletedAt && defaultRender,
		},
	];
};
