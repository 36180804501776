import { t } from "i18next";
import moment from "moment";
import { TBanner } from "types/TBanners";

import { IColumn } from "@components/types";

export const BannersViewColumns: IColumn[] = [
	{
		name: "Type",
		value: "type",
		render: (item: TBanner) => {
			return t(`banners.types.${item.type}`);
		},
	},
	{
		name: "Titre",
		value: "title",
	},
	{
		name: "Message",
		value: "content",
		className: "max-w-[300px]",
		render: (item: TBanner) => (
			<div className="truncate" title={item.content}>
				{item.content}
			</div>
		),
	},
	{
		name: "Date de création",
		value: "createdAt",
		render: (item: TBanner) =>
			!!item.createdAt &&
			moment(item.createdAt).format(t("dates.medium")),
		hidden: (props) =>
			props.items?.every((item: TBanner) => !item.createdAt),
	},
	{
		name: "Date de suppression",
		value: "deletedAt",
		render: (item: TBanner) =>
			!!item.deletedAt &&
			moment(item.deletedAt).format(t("dates.medium")),
		hidden: (props) =>
			props.items?.every((item: TBanner) => !item.deletedAt),
	},
	{
		name: "Visibilité",
		value: "visibility",
		render: (item: TBanner) => {
			return t(`banners.visibility.${item.visibility}`);
		},
	},
];
