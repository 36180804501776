import api from "./api";

interface IResetPasswordData {
	password: string;
	token: string;
}

class SecurityServiceClass {
	login = (email: string, password: string) =>
		api
			.post("/security/login", { email, password })
			.then((res) => res.data);

	logout = () => api.post("/security/logout");

	forgotPassword = (email: string) =>
		api
			.post("/security/forgot-password", {
				email,
			})
			.then((res) => res.data);

	resetPassword = (data: IResetPasswordData) =>
		api.post("/security/reset-password", data).then((res) => res.data);

	testResetToken = (token: string) =>
		api.get(`/security/test-reset-token/${token}`).then((res) => res.data);
}

const SecurityService = new SecurityServiceClass();

export default SecurityService;
