import {
	eachDayOfInterval,
	endOfWeek,
	getTime,
	isSameDay,
	isSaturday,
	isWeekend,
	startOfWeek,
} from "date-fns";
import { isSunday } from "date-fns";

import {
	convertHundredsToHours,
	formatDate,
	formatHundredsToString,
} from "./date";
import { isObjectEmpty } from "./objects";
import {
	convertHoursToHundreds,
	formatStringToHundreds,
	initDaysConverted,
} from "./timesheets";
import { ITimeSheetBonus, ITimeSheetDay } from "components/types";

export const OPTION_DATE: {
	weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6;
	step: number;
} = { weekStartsOn: 1, step: 1 };

export const initBonusesDaysHundreds = (
	days: ITimeSheetDay[],
	isHundredMode: boolean,
	type: string
) => {
	if (!days?.length) return [];

	const daysConverted = days.reduce(
		(acc: ITimeSheetDay[], day: ITimeSheetDay) => {
			if (["H", "G", "S", "D"].includes(type)) {
				acc.push({
					...day,
					value: isHundredMode
						? formatStringToHundreds(day.value)
						: convertHoursToHundreds(day.value, ":"),
				});
			} else {
				acc.push({ ...day });
			}
			return acc;
		},
		[]
	);
	return daysConverted;
};

const defineBonusValue = (bonus: ITimeSheetBonus, equalDate: ITimeSheetDay) => {
	if (["H", "S", "D"].includes(bonus.type))
		return bonus.name?.match(/Nuit/i)
			? equalDate.nightHours
			: equalDate.workedHours;

	if (["T", "W"].includes(bonus.type)) {
		if (equalDate.workedHours === 0) return 0;
		return Number(equalDate.workedHours) > 0 ? 1 : "";
	}
	return "";
};

export const initBonusValues = (
	bonus: ITimeSheetBonus,
	timesheetDays: ITimeSheetDay[],
	weekDays: ITimeSheetDay[],
	autoFill = false
) => {
	const bonusFormated = { ...bonus, days: bonus.days || [] };

	if (bonus.type === "M")
		return {
			...bonus,
			value: bonus.value || null,
			days: initBonusTotal(bonusFormated, weekDays),
		};

	if (["W", "S", "D"].includes(bonus.type))
		return {
			...bonus,
			days: initBonusWeekend(
				bonusFormated,
				weekDays,
				timesheetDays,
				autoFill,
				bonus.type === "S"
					? "saturday"
					: bonus.type === "D"
					? "sunday"
					: undefined
			),
		};

	return {
		...bonus,
		days: initBonusDays(bonusFormated, weekDays, timesheetDays, autoFill),
	};
};

export const initBonusTotal = (bonus: ITimeSheetBonus, week: ITimeSheetDay[]) =>
	week.reduce((acc: ITimeSheetDay[], item: ITimeSheetDay) => {
		acc.push({
			id: getTime(new Date(item.date)) * -1,
			date: item.date,
			isWorkingDay: false,
			isVacationDay: false,
			value: "",
			dateIsInTimesheet: false,
			isNonWorkingDay: item.isNonWorkingDay,
		});
		return acc;
	}, []);

const targetDay = (date: number | Date, target: string) => {
	if (target === "saturday") return isSaturday(date);
	if (target === "sunday") return isSunday(date);
	return isWeekend(date);
};

export const initBonusWeekend = (
	bonus: ITimeSheetBonus,
	week: ITimeSheetDay[],
	tsDays: ITimeSheetDay[],
	autoFill: boolean = false,
	target = ""
) => {
	return week.reduce(
		(acc: ITimeSheetDay[], { date, isNonWorkingDay }: any) => {
			let findEqualDate: ITimeSheetDay | any = {};
			let findNewDate = false;

			if (bonus.days && bonus.days.length > 0) {
				bonus.days.forEach((day) => {
					if (
						day &&
						targetDay(date, target) &&
						formatDate(date) === formatDate(day.date)
					) {
						findEqualDate = day;
					}
				});
			} else if (tsDays && tsDays.length > 0) {
				tsDays.forEach((day) => {
					if (
						day &&
						targetDay(date, target) &&
						formatDate(date) === formatDate(day.date)
					) {
						findEqualDate = day;
						findNewDate = true;
					}
				});
			}

			if (!isObjectEmpty(findEqualDate)) {
				if (target && findNewDate && autoFill) {
					acc.push({
						...findEqualDate,
						value: defineBonusValue(bonus, findEqualDate),
						isWorkingDay: targetDay(
							new Date(findEqualDate.date),
							target
						),
						dateIsInTimesheet: targetDay(
							new Date(findEqualDate.date),
							target
						),
						isWeekend: targetDay(
							new Date(findEqualDate.date),
							target
						),
						type: bonus.type,
					});
					findNewDate = false;
				} else {
					const isWorkingDay = targetDay(
						new Date(findEqualDate.date),
						target
					);
					acc.push({
						...findEqualDate,
						isWorkingDay,
						dateIsInTimesheet: isWorkingDay,
						isWeekend: isWorkingDay,
						type: bonus.type,
					});
				}
			}

			if (isObjectEmpty(findEqualDate)) {
				acc.push({
					id: getTime(date) * -1,
					date,
					isVacationDay: false,
					value: "",
					isWorkingDay: targetDay(date, target),
					dateIsInTimesheet: targetDay(date, target),
					isWeekend: targetDay(date, target),
					isNonWorkingDay,
				});
			}

			return acc;
		},
		[]
	);
};

export const initBonusDays = (
	bonus: ITimeSheetBonus,
	week: ITimeSheetDay[],
	tsDays: ITimeSheetDay[],
	autoFill = false
) =>
	week.reduce((acc: any, { date }) => {
		const { dateIsInTimesheet }: any = tsDays.find((day) =>
			isSameDay(new Date(day.date), new Date(date))
		);

		let findEqualDate: any = {};
		let findNewDate = false;
		if (bonus.days && bonus.days.length > 0) {
			bonus.days.forEach((day) => {
				if (day && formatDate(date) === formatDate(day.date)) {
					findEqualDate = { ...day };
				}
			});
		} else if (tsDays && tsDays.length > 0) {
			tsDays.forEach((day) => {
				if (day && formatDate(date) === formatDate(day.date)) {
					findEqualDate = { ...day };
					findNewDate = true;
				}
			});
		}
		if (!isObjectEmpty(findEqualDate)) {
			if (findNewDate && autoFill) {
				acc.push({
					...findEqualDate,
					value: defineBonusValue(bonus, findEqualDate),
					dateIsInTimesheet,
					type: bonus.type,
				});
				findNewDate = false;
			} else {
				acc.push({
					...findEqualDate,
					value: findEqualDate.value ? findEqualDate.value : "",
					dateIsInTimesheet,
					type: bonus.type,
				});
			}
		}

		if (isObjectEmpty(findEqualDate)) {
			acc.push({
				id: getTime(date) * -1,
				date,
				isWorkingDay: false,
				isVacationDay: false,
				value: "",
				dateIsInTimesheet,
				isWeekend: isWeekend(date) && dateIsInTimesheet,
				type: bonus.type,
			});
		}
		return acc;
	}, []);

export const defineWeekDays = (
	date: string | Date,
	timesheetDays: ITimeSheetDay[] = []
) => {
	const week = eachDayOfInterval(
		{
			start: startOfWeek(new Date(date), OPTION_DATE),
			end: endOfWeek(new Date(date), OPTION_DATE),
		},
		OPTION_DATE
	).map((item) => {
		const isVacationDay: ITimeSheetDay | any =
			timesheetDays.find((day: any) =>
				isSameDay(new Date(day.date), new Date(item))
			) || {};
		if (!isObjectEmpty(isVacationDay))
			return { date: item, isVacationDay: isVacationDay?.isVacationDay };
		return { date: item, isVacationDay: false };
	});
	return week;
};

const checkZeroValue = (dayValue: string) => {
	return dayValue && dayValue !== "00:00" && dayValue !== "00,00";
};

export const autoFillBonuses = (
	bonus: ITimeSheetBonus,
	days: ITimeSheetDay[],
	mode: string
) => {
	if (!(bonus.days && bonus.days.length && days.length)) return bonus;

	const newBonus = bonus.days.reduce((acc: ITimeSheetDay[], item) => {
		days.forEach((day: ITimeSheetDay) => {
			const canFill =
				(mode === "halfFill" && !item.value) || mode === "fullFill";

			if (!isSameDay(new Date(day.date), new Date(item.date))) return day;
			const nightHoursValue = checkZeroValue(
				(day.nightHours ?? "00:00") as string
			)
				? day.nightHours
				: "";
			const workedHoursValue = checkZeroValue(
				(day.workedHours ?? "00:00") as string
			)
				? day.workedHours
				: "";

			if (
				(bonus.type === "H" ||
					(bonus.type === "S" && item.isWeekend) ||
					(bonus.type === "D" && item.isWeekend)) &&
				canFill
			) {
				acc.push({
					...item,
					value: bonus.name.match(/Nuit/i)
						? nightHoursValue
						: workedHoursValue,
				});
			} else if (
				(bonus.type === "T" ||
					(bonus.type === "W" && item.isWeekend)) &&
				canFill
			) {
				acc.push({
					...item,
					value: workedHoursValue ? 1 : "",
				});
			} else {
				acc.push({ ...item });
			}

			return day;
		});
		return acc;
	}, []);

	return { ...bonus, days: newBonus };
};

export const checkExistingValuesBonusesType = (values: ITimeSheetBonus[]) => {
	const bonusesValues = values.reduce(
		(acc: number, { days }: ITimeSheetBonus) => {
			if (!days?.length) return acc;

			const daysValues: number = days.reduce(
				(accBonus: number, item: any) => {
					if (["H", "S", "D", "T", "W"].includes(item.type))
						return item.value || item.value === 0
							? accBonus + 1
							: accBonus;

					return accBonus;
				},
				0
			);
			return daysValues > 0 ? acc + 1 : acc;
		},
		0
	);
	return bonusesValues > 0;
};

export const checkExistingValuesBonuses = (values: ITimeSheetBonus[]) => {
	const bonusesValues = values.reduce(
		(acc: number, { days }: ITimeSheetBonus) => {
			if (!days?.length) return acc;

			const daysValues = days.reduce((accBonus: number, item) => {
				return item.value || item.value === 0 ? accBonus + 1 : accBonus;
			}, 0);
			return daysValues > 0 ? acc + 1 : acc;
		},
		0
	);
	return bonusesValues > 0;
};

export const convertDaysBonusesHundreds = (
	tsDays: ITimeSheetDay[],
	tsBonuses: ITimeSheetBonus[],
	isHundredMode: boolean
) => {
	let days: ITimeSheetDay[] = [];
	let bonuses: ITimeSheetBonus[] = [];
	if (tsDays && tsDays.length) {
		days = tsDays.reduce((acc: ITimeSheetDay[], item) => {
			if (item.id > 0) {
				acc.push({
					...item,
					workedHours: isHundredMode
						? formatStringToHundreds(item.workedHours)
						: convertHoursToHundreds(item.workedHours, ":"),
					nightHours: isHundredMode
						? formatStringToHundreds(item.nightHours)
						: convertHoursToHundreds(item.nightHours, ":"),
				});
			}
			return acc;
		}, []);
	}
	if (tsBonuses && tsBonuses.length) {
		bonuses = tsBonuses.reduce(
			(acc: ITimeSheetBonus[], bonus: ITimeSheetBonus) => {
				let bonusDays: ITimeSheetDay[] = [];
				if (bonus.days?.length) {
					bonusDays = bonus.days.reduce(
						(accBonus: ITimeSheetDay[], day) => {
							if (day.dateIsInTimesheet) {
								accBonus.push({
									...day,
									date: formatDate(day.date, "yyyy-MM-dd"),
									value: ["H", "G", "S", "D"].includes(
										bonus.type
									)
										? isHundredMode
											? formatStringToHundreds(day.value)
											: convertHoursToHundreds(
													day.value,
													":"
											  )
										: day.value === ""
										? null
										: day.value,
								});
							}
							return accBonus;
						},
						[]
					);
				}
				acc.push({
					...bonus,
					days: bonusDays,
					bonusId: !bonus.bonusId ? bonus.id : bonus.bonusId,
				});

				return acc;
			},
			[]
		);
	}

	return { days, bonuses };
};

export const convertDaysBonusesString = (
	tsDays: ITimeSheetDay[],
	tsBonuses: ITimeSheetBonus[],
	isHundredMode: boolean
) => {
	let days = [];
	let bonuses: ITimeSheetBonus[] = [];
	if (tsDays?.length) days = initDaysConverted(tsDays, isHundredMode);

	if (tsBonuses?.length) {
		bonuses = tsBonuses.reduce((acc: ITimeSheetBonus[], bonus: any) => {
			acc.push({
				...bonus,
				days: ["H", "G", "S", "D"].includes(bonus.type)
					? initBonusesDaysConverted(bonus.days, isHundredMode)
					: bonus.days,
			});
			return acc;
		}, []);
	}
	return { days, bonuses };
};

export const initBonusesDaysConverted = (
	days: ITimeSheetDay[],
	isHundredMode: boolean
) => {
	if (!days?.length) return [];

	const daysConverted = days.reduce((acc: ITimeSheetDay[], day: any) => {
		acc.push({
			...day,
			value:
				day.value !== null || day.value !== ""
					? isHundredMode
						? formatHundredsToString(day.value)
						: convertHundredsToHours(day.value)
					: day.value,
		});
		return acc;
	}, []);
	return daysConverted;
};

export const initBonus = (
	bonuses: ITimeSheetBonus[],
	tsDays: ITimeSheetDay[],
	weekDays: ITimeSheetDay[]
) => {
	const newBonuses = bonuses.reduce((accBonus: ITimeSheetBonus[], bonus) => {
		const formatDays = initBonusValues(bonus, tsDays, weekDays);
		accBonus.push(formatDays);
		return accBonus;
	}, []);
	return newBonuses;
};
