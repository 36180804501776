import { t } from "i18next";

export const NOTIFICATIONS_SETTINGS_HOURS = [8, 10, 12, 14, 16, 18, 20, 22];

export const hoursList = NOTIFICATIONS_SETTINGS_HOURS.map((value, index) => ({
	value: index + 1,
	label: `${value}h`,
	raw: value,
}));

export const HUNDREDS_MODE = "hundreds";
export const HOURS_MODE = "hours";

export const OPTION_DATE = { weekStartsOn: 1 };

export const PER_PAGE = 50;
export const PER_PAGE_USERS = 20;

export const MAX_HUNDREDS = 16;
export const MAX_HUNDREDS_MINUTES = 99;
export const MAX_HOURS = 16;
export const MAX_MINUTES = 59;

export const HOURS_BONUS_TYPES = ["H", "G", "S", "D"];

export const HOURS_DAYS_TYPES = [
	{
		title: t("timesheets.daysTypes.workedHours"),
		name: "workedHours",
	},
	{
		title: t("timesheets.daysTypes.nightHours"),
		name: "nightHours",
	},
	{
		title: t("timesheets.daysTypes.isNonWorkingDay"),
		subtitle: t("timesheets.daysTypes.isNonWorkingDaySubtitle"),
		name: "isNonWorkingDay",
	},
];
