import React from "react";

import { Field, useFormikContext } from "formik";
import { t } from "i18next";
import { Trans } from "react-i18next";
import * as Yup from "yup";

import { ICommercialPropositionFormSectionProps } from "../CommercialPropositionForm";
import { ICommercialPropositionFormValues } from "../CommercialPropositionFormValidation";
import { InfoMessage } from "@zolteam/react-ras-library";

import { cn } from "utils";

export interface ICPConditionSectionValues {
	compensationCoefficient: string;
	costCoefficient: string;
	complementaryHealthInsuranceRate: number;
}

export const CPConditionSectionInitialValues: ICPConditionSectionValues = {
	compensationCoefficient: "",
	costCoefficient: "",
	complementaryHealthInsuranceRate: 0.25,
};

export const CPConditionSectionValidation = {
	compensationCoefficient: Yup.string().required(t("forms.field.required")),
	costCoefficient: Yup.string().required(t("forms.field.required")),
	complementaryHealthInsuranceRate: Yup.number().required(
		t("forms.field.required")
	),
};

export const CPConditionSection: React.FC<
	ICommercialPropositionFormSectionProps
> = ({ locked }) => {
	const { values, errors, touched } =
		useFormikContext<ICommercialPropositionFormValues>();

	return (
		<div className="gap-10 col dark:text-white">
			<div className="p-4 border-2 rounded-3xl border-neutral-300">
				{t("commercialPropositions.form.sections.terms.compensation1")}

				<Field
					className={cn([
						"mx-1 text-center border-2 rounded-full inline-block",
						"dark:bg-neutral-800 dark:border-neutral-900",
						errors.compensationCoefficient &&
							touched.compensationCoefficient &&
							"border-red-500",
					])}
					placeholder={t(
						"commercialPropositions.form.sections.terms.coeffValue"
					)}
					name="compensationCoefficient"
					disabled={locked}
				/>

				{t("commercialPropositions.form.sections.terms.compensation2")}

				<Field
					className={cn([
						"mx-1 text-center border-2 rounded-full inline-block",
						"dark:bg-neutral-800 dark:border-neutral-900",
						errors.costCoefficient &&
							touched.costCoefficient &&
							"border-red-500",
					])}
					placeholder={t(
						"commercialPropositions.form.sections.terms.coeffValue"
					)}
					name="costCoefficient"
					disabled={locked}
				/>

				{t("commercialPropositions.form.sections.terms.compensation3")}
				{(errors.compensationCoefficient &&
					touched.compensationCoefficient) ||
				(errors.costCoefficient && touched.costCoefficient) ? (
					<InfoMessage withIcon color="error">
						{t("forms.field.fillAll")}
					</InfoMessage>
				) : null}
			</div>
			<div className="p-4 border-2 rounded-3xl border-neutral-300">
				<Trans
					i18nKey="commercialPropositions.form.sections.terms.conditions"
					components={{
						purple: (
							<pre
								className="inline font-bold underline text-primary-500"
								style={{
									fontFamily: "DM Sans",
								}}
							/>
						),
					}}
					values={{
						weekNumber: values.weeksNumber?.toString()?.length
							? values.weeksNumber
							: `            `,
					}}
				/>
			</div>
			<div>
				<Trans
					i18nKey={
						"commercialPropositions.form.sections.terms.conditions2"
					}
					components={{
						field: (
							<Field
								type="number"
								className={cn([
									"mx-1 text-center border-2 rounded-full inline-block appearance-textfield",
									"dark:bg-neutral-800 dark:border-neutral-900",
									errors.complementaryHealthInsuranceRate &&
										touched.complementaryHealthInsuranceRate &&
										"border-red-500",
								])}
								placeholder={t(
									"commercialPropositions.form.sections.terms.cotisationValue"
								)}
								name="complementaryHealthInsuranceRate"
								disabled={locked}
								min={0}
							/>
						),
					}}
				/>
				{errors.complementaryHealthInsuranceRate &&
				touched.complementaryHealthInsuranceRate ? (
					<InfoMessage withIcon color="error">
						{t("forms.field.fillAll")}
					</InfoMessage>
				) : null}
				{touched.complementaryHealthInsuranceRate &&
				values.complementaryHealthInsuranceRate < 0.19 ? (
					<InfoMessage withIcon color="warning">
						{t(
							"commercialPropositions.form.sections.terms.lessThan019"
						)}
					</InfoMessage>
				) : null}
			</div>
		</div>
	);
};
