import api from "./api";
import { IContractsSearchParams } from "components/types";

import { CONFIG_EXPORT_PDF, CONFIG_EXPORT_XLSX } from "constants_globals";

class ContractsServiceClass {
	contractSearch(data: IContractsSearchParams, signal?: AbortSignal) {
		return api
			.post("/contract/search", data, {
				signal,
			})
			.then((resp) => resp.data);
	}

	contractExport(data: IContractsSearchParams) {
		return api.post(
			"/contract/export-xlsx-by-search",
			data,
			CONFIG_EXPORT_XLSX
		);
	}

	contractDownload(id: number) {
		return api.get(`/contract/download/${id}`, CONFIG_EXPORT_PDF);
	}

	contractDownloadIds(data: FormData) {
		return api.post("/contract/download-by-ids", data, {
			...CONFIG_EXPORT_PDF,
			headers: {
				...CONFIG_EXPORT_PDF.headers,
				"Content-Type": "application/form-data",
			},
		});
	}

	contractZoeAccess(siteId: number, contractId: number) {
		return api
			.get(`/contract/zoe-access/${siteId}/${contractId}`)
			.then((resp) => resp.data);
	}
}

const ContractsService = new ContractsServiceClass();
export default ContractsService;
