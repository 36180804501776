import { TBannerEdit } from "types/TBanners";

import { TSelectOption } from "@components/types";

// label is handled by the translation "banners.types"
export const BANNERS_TYPES: TSelectOption[] = [
	{
		label: "Information",
		value: "information",
	},
	{
		label: "Advertissement",
		value: "advertisement",
	},
	{
		label: "Alert",
		value: "alert",
	},
];

export const BANNER_INITIAL_VALUES: TBannerEdit = {
	id: null,
	type: null,
	title: "",
	content: "",
	pdfUrl: null,
	visibility: "everyone",
	visibilities: ["agency_only", "client_only"],
};
