// @ts-nocheck
import React, { BaseSyntheticEvent, useLayoutEffect } from "react";

import moment from "moment";

import { endOfWeek, startOfWeek } from "date-fns";
import { fr } from "date-fns/locale/fr";
import ReactDatePicker, {
	ReactDatePickerProps,
	registerLocale,
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { IWeek } from "components/types";

import { cn } from "utils";

import "./DatePicker.scss";

registerLocale("fr", fr);

interface IDatePickerProps extends Omit<ReactDatePickerProps, "onChange"> {
	weekPicker?: boolean;

	onChange?: (date: Date | IWeek, event?: any) => void;
	onMonthChange?: (date: Date) => void;
}

export const DatePicker: React.FC<IDatePickerProps> = ({ ...props }) => {
	const isInitied = React.useRef(false);
	const calendarRef = React.useRef<any>(null);

	const handleChange = (date: Date, event: BaseSyntheticEvent<any>) => {
		if (!props.onChange) return;
		if (!props.weekPicker) return props.onChange(date, event);
		const start = startOfWeek(date, { weekStartsOn: 1 }); // Set week start (0 = Sunday, 1 = Monday, etc.)
		const end = endOfWeek(date, { weekStartsOn: 1 });

		if (props.onChange) return props.onChange({ start, end, date: date });
		resetAndGetActiveWeek();
	};

	const Wrapper = props.weekPicker ? "div" : React.Fragment;

	const resetAndGetActiveWeek = () => {
		if (!props.weekPicker || isInitied.current) return;
		const activeWeek = calendarRef.current?.querySelector(
			".react-datepicker__day--selected"
		);

		calendarRef.current
			?.querySelectorAll(".react-datepicker__week")
			.forEach((a: any) => a.classList.remove("ActiveWeek"));

		return activeWeek;
	};

	const handleMonthChange = (date: Date) => {
		if (props.weekPicker) {
			const activeWeek = resetAndGetActiveWeek();
			if (
				moment(date).format("YYYY-DD-MM") ===
				moment(props.selected).format("YYYY-DD-MM")
			)
				activeWeek
					?.closest(".react-datepicker__week")
					?.classList.add("ActiveWeek");
		}
		props.onMonthChange?.(date);
	};

	/* initial active week */
	useLayoutEffect(() => {
		const activeWeek = resetAndGetActiveWeek();

		activeWeek
			?.closest(".react-datepicker__week")
			?.classList.add("ActiveWeek");
	});

	return (
		<Wrapper
			{...(Wrapper === "div"
				? {
						className: cn([props.weekPicker && "WeekPicker"]),
						ref: calendarRef,
				  }
				: {})}
		>
			<ReactDatePicker
				{...props}
				onChange={handleChange}
				locale="fr"
				onMonthChange={handleMonthChange}
				dateFormat="dd/MM/yyyy"
				className={cn(["DatePicker", props.className])}
			/>
		</Wrapper>
	);
};
