import React, { useRef } from "react";

import { ErrorMessage, Field, useField } from "formik";
import { t } from "i18next";

import { InfoMessage } from "@zolteam/react-ras-library";

import { cn } from "utils";

import style from "./Textarea.module.scss";

// Define TypeScript interface for props
interface TextareaProps
	extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
	name: string;
	label?: string;
	placeholder?: string;
	className?: string;
	disabled?: boolean;
	maxLength?: number;
	autoScale?: boolean;
	required?: boolean;
}

export const Textarea: React.FC<TextareaProps> = ({
	name,
	label = "",
	placeholder = "",
	className = "",
	disabled = false,
	maxLength,
	autoScale,
	required,
	...props
}) => {
	const elementRef = useRef<HTMLTextAreaElement>(null);
	const [field, meta, helpers] = useField({ name });

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		let value = event.target.value === "" ? "" : event.target.value || "";

		if (maxLength) value = value.slice(0, maxLength);
		helpers.setValue(value);
	};

	const hasPlaceholder = placeholder !== "";

	return (
		<div
			className={cn([
				style.container,
				meta.error && meta.touched && style.error,
				className,
			])}
		>
			<div className={cn([style.wrapper, style.field])}>
				<Field
					innerRef={elementRef}
					as="textarea"
					id={name}
					data-testid={name}
					name={name}
					value={field.value === null ? "" : field.value}
					placeholder={hasPlaceholder ? placeholder : label}
					className={cn([style.textarea, "max-h-[500px]"])}
					disabled={disabled}
					onChange={handleChange}
					style={
						autoScale && elementRef.current
							? {
									height: elementRef.current.scrollHeight,
									overflow: "hidden",
							  }
							: undefined
					}
					{...props}
				/>
				<label
					className={cn([style.label, disabled && "!bg-transparent"])}
					htmlFor={name}
				>
					{label}
				</label>
			</div>
			<div className="flex justify-between gap-4">
				{required && (
					<div className="ml-6 !-mt-1 text-xs italic text-neutral-400">
						{t("forms.field.mandatory")}
					</div>
				)}
				{!!maxLength && (
					<span
						className={cn([
							"text-xs top-2 text-right text-neutral-400 dark:!bg-transparent ml-auto",
						])}
					>
						{field.value?.length || 0}&nbsp;/&nbsp;{maxLength}
					</span>
				)}
			</div>

			<ErrorMessage name={name}>
				{(msg: string) => (
					<InfoMessage color="error" withIcon>
						{msg}
					</InfoMessage>
				)}
			</ErrorMessage>
		</div>
	);
};

export default Textarea;
