import React from "react";

import { Form, Formik } from "formik";
import { t } from "i18next";
import { promiseToast } from "toastify";
import { TBannerEdit, TBannerVisibility } from "types/TBanners";
import * as Yup from "yup";

import { useMutation } from "@tanstack/react-query";

import BannersService from "services/BannersService";

import { TSelectOption } from "@components/types";
import { Button, Select, InfoMessage, Title } from "@zolteam/react-ras-library";
import { Field, Textarea, Toggle } from "components/atoms";

// import { FilesListItem, FileUploader } from "components/molecules";
import {
	BANNER_INITIAL_VALUES,
	BANNERS_TYPES,
} from "constants_globals/CBanners";

const BannerValidationSchema = () =>
	Yup.object().shape({
		type: Yup.object().required(t("forms.field.required")),
		title: Yup.string().required(t("forms.field.required")),
		content: Yup.string().required(t("forms.field.required")),
		visibilities: Yup.array().min(1, t("banners.visibility.required")),
	});

interface IEditBannerFormProps {
	onClose: () => void;
	onSuccess: () => void;
	initialValues?: TBannerEdit;
}

export const EditBannerForm: React.FC<IEditBannerFormProps> = ({
	onClose,
	onSuccess,
	initialValues,
}) => {
	const getVisibility = (visibilities: TBannerVisibility[]) => {
		if (
			visibilities.includes("agency_only") &&
			visibilities.includes("client_only")
		) {
			return "everyone";
		}
		return visibilities[0];
	};

	const handleSubmit = ({ file, ...values }: TBannerEdit) => {
		let prom = Promise.resolve();

		const pdfUrl = file?.pjFile
			? "data:application/pdf;base64," + file?.pjFile
			: values.pdfUrl || null;

		const visibility = getVisibility(values.visibilities || []);

		if (values.id) {
			prom = BannersService.updateBanner({
				...values,
				pdfUrl,
				type: values.type?.value as string,
				visibility,
			});
		} else {
			prom = BannersService.addBanner({
				...values,
				pdfUrl,
				type: values.type?.value as string,
				visibility,
			});
		}

		return promiseToast(prom);
	};

	const submitMutation = useMutation({
		mutationFn: handleSubmit,
		onSuccess: () => {
			onSuccess();
		},
	});

	return (
		<Formik
			initialValues={{
				...BANNER_INITIAL_VALUES,
				...initialValues,
			}}
			onSubmit={(values) => submitMutation.mutate(values)}
			validationSchema={BannerValidationSchema()}
		>
			{({ values, setFieldValue, errors }) => {
				const handleVisibilityChange = (
					type: string,
					value: boolean
				) => {
					if (value) {
						setFieldValue("visibilities", [
							...values.visibilities,
							type,
						]);
					} else {
						setFieldValue(
							"visibilities",
							values.visibilities?.filter((v) => v !== type)
						);
					}
				};

				return (
					<Form className="gap-4 col">
						{values.isFromExisting && (
							<InfoMessage color="primary" withIcon>
								{t("banners.enableInfo")}
							</InfoMessage>
						)}
						<Select
							name="type"
							label={t("banners.type")}
							placeholder={t("banners.type")}
							options={BANNERS_TYPES}
							onChange={(value: TSelectOption) =>
								setFieldValue("type", value)
							}
							value={values.type}
							getOptionLabel={(option: TSelectOption) =>
								t(`banners.types.${option.value}`)
							}
						/>
						<Field
							name="title"
							label={t("forms.title")}
							placeholder={t("forms.title")}
							maxLength={255}
						/>
						<Textarea
							name="content"
							label={t("forms.message.label")}
							placeholder={t("forms.message.label")}
							maxLength={500}
						/>
						<div className="mb-4">
							<Title
								tag="h3"
								size="heading03"
								className="!leading-3 mb-4"
							>
								{t("banners.visibility.title")}
							</Title>
							<div className="flex flex-row gap-8 mb-2">
								<Toggle
									name="visibilities"
									label={t("banners.visibility.agencies")}
									checked={values.visibilities.includes(
										"agency_only"
									)}
									onChange={(event) =>
										handleVisibilityChange(
											"agency_only",
											event.target.checked
										)
									}
								/>
								<Toggle
									name="visibilities"
									label={t("banners.visibility.clients")}
									checked={values.visibilities.includes(
										"client_only"
									)}
									onChange={(event) =>
										handleVisibilityChange(
											"client_only",
											event.target.checked
										)
									}
								/>
							</div>
							{!!values.visibilities?.length && (
								<InfoMessage color="primary" withIcon>
									{t(
										`banners.visibility.${getVisibility(
											values.visibilities
										)}InfoMessage`
									)}
								</InfoMessage>
							)}
							{(errors.visibilities ||
								!values.visibilities?.length) && (
								<div className="w-full [&>*]:w-full">
									<InfoMessage color="error" withIcon>
										{errors.visibilities}
									</InfoMessage>
								</div>
							)}
						</div>
						{/*<div className="mb-4">
							{values.file ? (
								<FilesListItem
									file={values.file}
									onRemove={() => setFieldValue("file", null)}
									disabled={submitMutation.isPending}
								/>
							) : (
								<FileUploader
									disabled={submitMutation.isPending}
									onChange={(event) => {
										const file = event.target.files?.[0];
										if (!file) return;

										const reader = new FileReader();
										reader.onload = (e) => {
											const fileData = {
												pjName: file.name,
												pjFile: (
													e.target?.result as string
												)?.split(",")[1],
											};

											setFieldValue("file", fileData);
										};
										reader.readAsDataURL(file);
									}}
									accept="application/pdf"
								/>
							)}
						</div>*/}
						{submitMutation.error && (
							<InfoMessage color="error" withIcon>
								{submitMutation.error.message}
							</InfoMessage>
						)}
						<div className="gap-4 row">
							<Button
								outline
								type="button"
								color="primary"
								className="w-full"
								onClick={onClose}
								disabled={submitMutation.isPending}
							>
								{t("global.cancel")}
							</Button>
							<Button
								type="submit"
								color="primary"
								className="w-full"
								isLoading={submitMutation.isPending}
							>
								{t("global.save")}
							</Button>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};
